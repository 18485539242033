<template>
    <div class="collection">
        <div class="title">我的收藏</div>
        <div class="report-list">
            <div v-for="(list, index) of reportData" v-if="show" :key="index" class="lists-box">
                <img :src="list.ttwTpDO.ydlj" v-if="list.ttwTpDO!=undefined&&list.ttwTpDO.ydlj!=undefined"/>
                <img :src="imgSrc" v-else>
                <div class="text-box">
                    <!-- <div class="title-box"> -->
                    <span class="text-title" @click="toDetail(list)">{{ list.bt }}</span>
                    <!-- </div> -->
                    <span class="text-time">{{ list.fbsj }}</span>
                    <p class="text-con">{{ list.wznr }}</p>
                </div>
                <div class="btn-box">
					<span :class="[list.isCollect?'ifCollect':'unCollect']" @click="deleteRow(list,index)">
						{{ list.collectText }}
					</span>
                    <button @click="toDetail(list)">查看详情</button>
                </div>
            </div>
        </div>
        <div class="policy-list">
            <div v-for="(list, index) of policyData" :key="index">
                <el-row>
                    <el-col :span="4" class="date-col">
                        <span class="year">{{ year(list.fbsj) }}年</span>
                        <span class="date">{{ date(list.fbsj) }}</span>
                    </el-col>
                    <el-col :span="16">
                        <p class="con-title" @click="toDetail(list)">{{ list.bt }}</p>
                        <p class="con-text">{{ list.wznr }}</p>
                    </el-col>
                    <el-col :span="4" class="todetail">
				  <span :class="[list.isCollect?'ifCollect':'unCollect']" @click="deleteRow(list,index)">
				      {{ list.collectText }}
				  </span>
                        <button @click="toDetail(list)">查看详情</button>
                    </el-col>
                </el-row>
            </div>
        </div>
        <not-found v-if="reportData.length == 0 && policyData.length == 0"></not-found>
    </div>
</template>
<script>
import {checkCollection, getGraphByCollection} from "../../../api/personService";
import notFound from "../../../components/notFound";

export default {
    data() {
        return {
            show: true,
            reportData: [],
            policyData: [],
            imgSrc: require('@/assets/images/footer/xwbd.jpg'),
        }
    },
    mounted() {
        this.getGraphList();
    },
    components: {
        notFound
    },
    methods: {
        /**
         * 获取收藏图文列表
         */
        getGraphList() {
            getGraphByCollection().then(res => {
                const infos = res.data;
                const newsInfos = [];
                const policyInfos = [];
                if (infos.length > 0) {
                    infos.forEach(item => {
                        item.isCollect = true;
                        item.collectText = '取消收藏';
                        if (item.lx == '1' || item.lx == '3') {
                            newsInfos.push(item);
                        } else {
                            policyInfos.push(item)
                        }
                    })
                }
                this.reportData = newsInfos;
                this.policyData = policyInfos;
            })
        },

        /**
         * 跳转详情
         * @param item
         */
        toDetail(item) {
            let name = 'newsDetail';
            if (item.lx == '1') {
                name = 'newsDetail'
            } else if (item.lx == '2') {
                name = 'noticeDetail'
            } else if (item.lx == '6' || item.lx == '7') {
                item = 'policyDetail'
            } else if (item.lx == '4') {
                item = 'localExperienceDetail'
            }
            this.$router.push({
                name: name,
                query: {
                    id: item.id
                }
            });
        },

        /**
         * 取消收藏
         * @param item
         */
        deleteRow(item, index) {
            const param = {graphId: item.id, type: 'collection'};
            checkCollection(param).then(res => {
                if (res.data == '0') {
                    if (item.lx == '1' || item.lx == '3') {
                        this.reportData[index].isCollect = false;
                        this.reportData[index].collectText = '收藏';
                    } else if (item.lx == '5') {
                        this.policyData[index].isCollect = false;
                        this.policyData[index].collectText = '收藏';
                    }
                    this.$message('取消成功');
                } else if (res.data == '1') {
                    if (item.lx == '1' || item.lx == '3') {
                        this.reportData[index].isCollect = true;
                        this.reportData[index].collectText = '取消收藏';
                    } else if (item.lx == '5') {
                        this.policyData[index].isCollect = true;
                        this.policyData[index].collectText = '取消收藏';
                    }
                    this.$message('成功');
                } else {
                    this.$message({
                        message: res.data,
                        type: 'warning'
                    })
                }
            })
        },

        year(value) {
            return value.substring(0, 4)
        },
        date(value) {
            return value.substring(5).replace('.', '/')
        }
    }
}
</script>
<style lang="scss">
.collection {
  padding: 20px 20px;
  box-sizing: border-box;

  .title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }

  .report-list {
    .lists-box {
      height: 160px;
      box-shadow: 0px 1px 0px 0px #B8CDE6;
      padding: 0px 25px 0px 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        width: 180px;
        height: 120px;
        margin-right: 20px;
        float: left;
      }

      .text-box {
        overflow: hidden;
        width: calc(100% - 355px);

        .text-title {
          font-size: 18px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: rgba(81, 82, 84, 1);
          line-height: 25px;
          cursor: pointer;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .text-time {
          display: block;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(150, 150, 150, 1);
          line-height: 35px;
        }

        .text-con {
          font-size: 14px;
          font-family: Alibaba PuHuiTi;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }

      }

      .btn-box {
        width: 153px;
        height: 120px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .ifCollect {
          cursor: pointer;
          font-size: 14px;
          line-height: 25px;
          background: url(../../../assets/images/icon_collect.png) no-repeat left center;
          padding-left: 18px;
          background-size: 16px 16px;
          margin-right: 6px;

        }

        .unCollect {
          cursor: pointer;
          font-size: 14px;
          line-height: 25px;
          background: url(../../../assets/images/icon-unclolect.png) no-repeat left center;
          padding-left: 18px;
          background-size: 16px 16px;
          margin-right: 6px;
        }

        button {
          width: 100px;
          height: 30px;
          cursor: pointer;
          background: rgba(3, 155, 229, 1);
          border: none;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          margin-top: 45px;
        }
      }
    }

  }

  .policy-list {
    .el-row {
      height: 136px;
      box-shadow: 0px 1px 0px 0px #B8CDE6;
      // margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .date-col {
        display: flex;
        flex-direction: column;
        text-align: center;

        .year {
          font-size: 16px;
          font-family: 'PangMenZhengDao';
          font-weight: 400;
          padding: 18px 0px 5px 0px;
          color: rgba(255, 148, 83, 1);
        }

        .date {
          font-size: 16px;
          font-family: REEJI-HonghuangLi-MediumGB1 .0;
          font-weight: 400;
          color: rgba(255, 148, 83, 1);
        }
      }

      .todetail {
        // padding-top: 22px;
        .ifCollect {
          cursor: pointer;
          font-size: 14px;
          line-height: 25px;
          background: url(../../../assets/images/icon_collect.png) no-repeat left center;
          padding-left: 18px;
          background-size: 16px 16px;
          margin: 22px 0px 0px 52px;
        }

        .unCollect {
          cursor: pointer;
          font-size: 14px;
          line-height: 25px;
          background: url(../../../assets/images/icon-unclolect.png) no-repeat left center;
          padding-left: 18px;
          background-size: 16px 16px;
          margin: 22px 0px 0px 52px;
        }

        button {
          width: 100px;
          height: 30px;
          cursor: pointer;
          background: rgba(3, 155, 229, 1);
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          border: none;
          margin: 22px 0px 0px 30px;
        }
      }

      .el-col-16 {
        .con-title {
          max-height: 30px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          line-height: 30px;
          // margin:22px 0px 15px 0px;
          cursor: pointer;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .con-text {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(102, 102, 102, 1);
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          margin-top: 10px;
        }
      }

      .date-col {
        min-height: 80px;
        background: url(../../../assets/images/icon_date_bg.png) 20px center no-repeat;
      }
    }


  }
}

</style>
